import React, { Component } from "react";
import { Link } from "react-router-dom";
import { PageContent, PageHeader, Text, Paragraph, Box } from "grommet";

export class Home extends Component {
  render() {
    return (
      <PageContent>
        <PageHeader
          title="Welcome to É.DUB.BA"
          subtitle="School of Hittite Cuneiform"
        />
        <Paragraph textAlign="left" fill>
          The É.DUB.BA project is a digital version of a house of tablets, a
          type of school where scribes learned to read and write cuneiform. It
          is an educational platform that aims to teach individuals how to read
          and write in Hittite cuneiform, a system of writing that uses
          wedged-shaped marks on clay tablets. To create the platform, I
          analyzed a large corpus of Hittite texts and identified 60,000
          lexemes, or individual words, made up of 17,000 unique signs. From
          this analysis, I developed a series of lessons, each focusing on five
          specific signs. The lessons are structured in a way that allows
          learners to gradually build up their reading skills, with each new
          lesson building on the knowledge gained in previous ones. As a result,
          learners will be able to read an increasingly larger portion of the
          corpus as they progress through the lessons, with the goal of
          eventually being able to read 80% of the corpus after completing
          lesson 20.
        </Paragraph>
        <Box alignSelf="center">
          <Link to="/lesson/1">
            <Text size="large" color="brand">
              Start Learning!
            </Text>
          </Link>
        </Box>
      </PageContent>
    );
  }
}

export default Home;
